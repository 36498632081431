@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

* {
  box-sizing: border-box;
}
  body { 
    font-family: 'Roboto Condensed', sans-serif !important;
}
#root {
 --primary-white: #ffffff;
 --primary-light-gray: #f4f4f4;
 --primary-gray: #ccd1d9;
 --primary-gray: #666666;
 --primary-orange: #f9ab00;
 --primary-dark: rgb(52, 58, 64); 
 --primary-red: #ef4035;
 --primary-hover-red: #f33501;
 --primary-black: #000000;
 --primary-dark-gray: #666666;
 --primary-green: green;
}
nav {
  z-index: 2;
}

nav a.nav-link {
  /* font-weight: 500; */
  letter-spacing: 0.0375rem;
  cursor: pointer;
}
nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}
.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}
@media(max-width: 768px){
  .navbar-brand {
    width: 7rem;
  }
}
.main-info h1 {
  font-size: 1.5rem !important;
}
.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}
.navbar-nav > li > a.active, .navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

.header-wraper {
  position: relative;
  background: url(https://images.unsplash.com/photo-1537432376769-00f5c2f4c8d2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjh8fHNvZnR3YXJlJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}
.main-info h1 {
  color: var(--primary-orange);
  text-transform: uppercase;
}

.typed-text {
  font-size: 2rem;
  color: var(--primary-white)
}

  @media(max-width: 768px){
    .main-info h1 {
      font-size: 1.7rem !important;
    }
    .typed-text {
      font-size: 1.3rem !important;
    }
  }
  .main-info h1 {
    font-size: 1.5rem !important;
  }

.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition: .2s ease-in-out;
  cursor: pointer;
}

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}
@media(max-width: 768px) {
  .tsparticles-canvas {
  display: none;
  }
}

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: .3rem;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: var(--primary-orange);
  text-transform: uppercase;
  margin-top: 3rem;
}

@media(max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-gray);
}
.services h1{
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon{
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

.resume {
  padding: 0.7rem;
}

.resume-wrapper {
  position: relative;
  padding: 3.125rem;
  overflow: hidden;
}

.resume h1 {
  text-transform: uppercase;
  color: var(--primary-orange)
}

.resume-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.06625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-gray);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: ltr;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-gray);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-red);
  font-weight: 900;
}

@media(max-width: 768px) {
  .resume .container {
    padding-left: 0;
  }
  .resume-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

.portfolio-wrapper {
  background: var(--primary-light-gray);
  padding: 3rem 0;
}

.portfolio-wrapper h1 {
  color: var(--primary-orange);
}

.portfolio-image {
  width: 15rem;
  height: 12rem;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 12rem;
  top: 0;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow{
  opacity: 60%;
  transition: 0.3s ease-in-out;
}
.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popupbox-wrapper {
  margin-top: 7rem;
  
}

.portfolio-image-popupbox {
  width: 35rem;
  padding: 0 0.5rem;
}
.hyper-link {
  cursor: pointer;
  color: var(--primary-orange);
}

.hyper-link:hover {
  color: var(--primary-red);
}

@media(max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media(max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }

  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media(max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }

  .portfolio-image-popupbox {
    width: 30%;
  }
}

.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-gray);
  font-size: 1.2rem;
}

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-gray);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  margin-bottom: 1rem;
  padding-left: 0 !important;
} 

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus { 
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid var(--primary-dark-gray);   
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-hover-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}

@media(max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }
  .contacts .container {
    padding: 0 1rem;
  }
  .contacts p {
    padding: 0 0.4rem;
  }
}

.footer {
  background: var(--primary-black);
  color: var(--primary-dark-gray);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-gray);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}

.resume-button:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

.resume-button:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}